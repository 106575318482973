// .container {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 621px;
//     height: 564px;
//     display: flex;
//     flex-direction: column;
//     gap: 30px;
// }

// .hero {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     position: relative;
// }

footer.footer{
    padding-top: 5rem;

    .enquiry-block {
        display: none;
    }
}
